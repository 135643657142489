@tailwind base;
@tailwind components;
@tailwind utilities;

[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border-color: unset;
  border-width: 0;
  border-radius: unset;
  padding: unset;
  font-size: unset;
  line-height: unset;
}
